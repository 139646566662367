// graphik
@font-face {
  font-family: averta;
  src: url(../fonts/Averta-Thin.otf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: averta;
  src: url(../fonts/Averta-Light.otf);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: averta;
  src: url(../fonts/Averta-Regular.otf);
  font-style: normal;
}
@font-face {
  font-family: averta;
  src: url(../fonts/Averta-Semibold.otf);
  font-weight: 700;
  font-style: normal;
}
 @font-face {
  font-family: averta;
  src: url(../fonts/Averta-Bold.otf);
  font-weight: 800;
  font-style: normal;
}