iframe {width: 100%;}
.barba-container {/*max-width: 2000px;*/margin: 0 auto;}

section, .section {
	padding: clamp(2vw, 8vmax, 30vw) 0;
	//@include padding(120px 0);
}

.pt-big {
	padding-top: clamp(4vw, 7vmax, 20vw);
	//@include padding(120px 0);
}

.container {padding: 0 11vw;width: 100%;}
.min-left {margin-left: -11vw;}
.min-right {margin-right: -11vw;}


.inner {/*max-width: 1400px;margin: 0 auto;  */  width: 100%;}
.inner-md {max-width: 980px;  width: 100%;}

.indent-right {margin-right: -66px;}
.indent-left {margin-left: -66px;}


/* Responsive up*/
@media only screen and (min-width:768px) {
	.mw-xsmall {max-width: 300px}
	.mw-small {max-width: 470px}
	.mw-medium {max-width: 620px}
}

/* Responsive down*/

@media only screen and (max-width:1280px) {
	.container {padding: 0 6vw;}
	.inner {max-width: 100%;}
}

@media only screen and (max-width:1023px) {
	.min-left {margin-left: -8vw;}
	.indent-right {margin-right: -40px;}
	.indent-left {margin-left: -40px;}

}

@media only screen and (max-width:767px) {
	.indent-right-sm {margin-right: -40px;}
	.indent-left-sm {margin-left: -40px;}

	.indent-right-sm-0 {margin-right: 0px;}
	.indent-left-sm-0 {margin-left: 0px;}

	.mt-min-sm {margin-top: -40px;}
	.mb-min-sm {margin-bottom: -40px;}

}

@media only screen and (max-width:575px) {
	.container-sm-left  {padding-left: 8vw;}

}


@include media-breakpoint-down(sm) {
	.min-right-sm {margin-right: -8vw}
	.min-left-sm {margin-left: -8vw}
}
