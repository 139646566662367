.js .fade-in {opacity: 0;-webkit-transform: translateY(20px);  transform: translateY(20px)}

.js .fade-in-delay {opacity: 0;-webkit-transform: translateY(20px);  transform: translateY(20px)}

.blue-reveal {position: absolute;left: 0;right: 0;top: 0;background-color: $color-blue;height: 0}



.zoom-in img {transform:scale(1.2)}




@include media-breakpoint-down(md) {
	.notransform-md-down {transform: none!important}

 }