

.btn-arrow {white-space: nowrap; transition:color 0.4s;display: inline-block;color: #363738; text-decoration: none;font-size: 13px; font-weight: 600; position: relative; transition:all 0.4s}
.btn-arrow.small {font-size: 16px;}
.btn-arrow:hover {color: #272D3D}
.btn-arrow span {padding-left: 20px;}

.btn-arrow span, .btn-arrow svg  {position: relative;z-index: 1;}
.btn-arrow svg path {transition:all 0.4s}
.btn-arrow svg {transition:all 0.4s}

.btn-arrow:hover svg {transform:translateY(2px)}
.btn-arrow:hover svg path {stroke:$body-color}


.link-white {color: #fff}
.link-white:hover {color: #fff; text-decoration: none;}

.link-location {text-decoration: none;}
.link-location:hover {color: #fff!important}
.link-location span{margin-left: 10px; display: inline-block;transition:transform 0.4s}
.link-location:hover span{transform:translateX(3px)}
.link-location span path{transition:fill 0.4s}
.link-location:hover span path{fill:rgba(83,233,193,0.8)}


@media (max-width: 959px) {
    .btn-arrow {font-size: 12px}

}