.text-blue, .color-blue {color:$color-blue!important }
.text-yellow, .color-yellow {color:$color-yellow!important }
.text-green, .color-green {color:$color-green!important }
.text-white, .color-white {color:#fff!important }
.text-off-white, .color-off-white {color:rgba(255,255,255,0.8)!important }
.text-dark, .color-dark {color:#040506!important }
.text-light, .color-light {color:#777A82!important }
.text-green {color:#53E9C1!important }
.text-purple {color:#BFADFD!important }
.text-orange {color:#F5D597!important }
.text-blue {color:#87EEFF!important }
.text-light-green {color: rgba(83, 233, 193,0.8)!important}

.bg-green {background-color:#53E9C1!important }
.bg-purple {background-color:#BFADFD!important }
.bg-orange {background-color:#F5D597!important }
.bg-blue {background-color:#87EEFF!important }
.bg-black {background-color:#1C1D1E!important } 



.nav-normal {background-color: #fff}