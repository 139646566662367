

/* Font family */
$font-primary: 'averta',sans-serif;

/* Colors */
$color-blue: #0E79B2 !default;
$color-yellow: #FFAD05 !default;
$color-dark-blue: #1C212F !default;
$color-green: #16f4d0 !default;
$color-light-green: #D9F7F4 !default;

/* Font-weight */
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
