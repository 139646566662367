.main-nav > nav { position: -webkit-sticky; position: sticky; top: 0; z-index: 9999;/*margin-top: 20px; */ }

.nav-container {/*transition:all 0.4s;*/will-change: auto;}

/************ NAVIGATION TRANSITION ************/
.nav-transition { position: fixed; left: 0; right: 0; z-index: 5 }

/************ NAVIGATION HOLDER ************/




    /* Spacer for section below nav */
    .nav-spacer {padding-top: 130px;}
    .nav-spacer-2 {padding-top: 12vw;}

    /* Hide nav on down */
    .main-nav {position: fixed;top: 0;left: 0;right: 0;z-index: 99; display: flex; flex-direction:column; justify-content: flex-end; height: 130px; background-color: #fff; transition: opacity 0.4s ease-in-out, visibility .4s ease-in-out, transform 0.6s 0.6s, background-color 0.4s 0s, height 0.4s ease-in-out;max-width: 2000px;margin: 0 auto;}



    .nav-right {width: 50%;}

    .main-nav .nav-inner {display: flex;justify-content: space-between;align-items: flex-end;position: relative;margin: 0 auto;padding-bottom: 30px;}



    .main-nav.nav-scroll-down {}
    .main-nav.nav-scroll-up { top: 0; opacity: 1; visibility: visible; }

    .nav-moved {  background-color: rgba(255,255,255,1); }

    .nav-moved.main-nav{height: 80px}
    



    /* Reset ul's*/
    .main-nav ul{margin-bottom: 0;margin-left: 0;padding-left: 0; list-style: none;}

/************ END NAVIGATION HOLDER ************/



/************ LOGO ************/
    .main-nav .logo {margin: 0 80px 0 0;}
    
    @media only screen and (min-width: 1450px) {
        
    }

    .main-nav .logo a {display: block;}
    .main-nav .logo path { transition:fill 0.6s}
    .main-nav .logo, .main-nav .logo img, .main-nav .logo svg {display: block; width: 190px; height: 27px; position: relative;z-index: 2 }
/************ END LOGO ************/



/************ MEGA MENU ************/
/* Main menu */
    .megamenu { flex: 1; margin-right: 0px;}
    .megamenu > ul {display: flex;justify-content: space-between;position: relative;}


/* Top level text */
    .megamenu > ul > li { padding: 0 30px 0 0; text-decoration: none; display: flex; align-items: center; ; position: relative;}
    .megamenu > ul > li:last-child {padding-right: 0;}
    .megamenu > ul > li.current-menu-item a:after { color: #FFAD05}
    .megamenu > ul > li > a { transition:color 0.4s; color: #040506; font-weight:600; font-size: 15px; line-height: 1; text-decoration: none;padding: 0;white-space: nowrap; position: relative;padding-left: 25px;}


    .megamenu > ul > li.current-menu-item > a{ color: #040506}
    .megamenu > ul > li > a:hover {color: #000}

/* Top level Hover line */
    .megamenu > ul > li > a:before { content: ''; display: block; position: absolute;bottom:2px; left: 0; height: 11px; transform: scaleX(0); width: 11px; background-color: #16F4D0; transition: transform 0.4s 0.4s; transform-origin: left; margin: 0 auto; }
    .megamenu > ul > li.current-menu-item  > a:before {transform: scaleX(1); }



/* Hide sub heading*/
    .holder-menu-sub > a {display: none!important;}

/* Links in menu */
    .holder-menu-sub > ul > li > a {font-size: 20px!important; }


/* Mega menu open opacity layer */
    .overlay {position: fixed;top: 0;right: 0;left: 0;bottom: 0;visibility: hidden;background-color: rgba(14,17,63,0.65);webkit-backdrop-filter: saturate(180%) blur(6px); backdrop-filter: saturate(180%) blur(6px);transition: opacity 0.2s, visibility 0s 0.2s;z-index: 5;opacity: 0;}
/************ END MEGA MENU ************/


/* Transparent menu*/
.nav-transparent.nav-top {background-color: transparent;}

.nav-transparent.nav-top .logo svg path {fill:#fff; transition:fill 0.4s}
//.menu-open .main-nav .logo svg path, .main-nav.menu-opened .logo svg path  {fill:red!important}
.nav-transparent-gradient:after { content: ''; position: absolute; left: 0; right: 0; top: 0; height: 30%; z-index: 1; background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }


/* DARK menu*/


body.nav-dark .main-nav {}
body.nav-dark .logo svg #logo-text path{fill:#fff}
body.nav-dark .nav-moved{  background-color: $color-dark-blue; }
body.nav-dark .main-nav.nav-scroll-down {background-color: $color-dark-blue; }



@media only screen and (max-width: 1600px) {
    .main-nav{height: 110px; }
    .nav-moved.main-nav{height: 110px}
    .nav-right {width: 60%;}
    .megamenu > ul > li { padding: 0 10px 0 0;}
    .megamenu > ul > li > a {padding-left: 18px;}
}
@media only screen and (max-width: 1300px) {
    .main-nav{height: 100px; }
    .nav-moved.main-nav{height: 80px}
    .nav-right {width: 70%;}

}




@media only screen and (min-width: 992px) {
    body.nav-dark .megamenu > ul > li > a {color: #fff;}
 }


@media only screen and (min-width: 992px) {
    .nav-transparent.nav-top .megamenu > ul > li > a {color: #fff; opacity: 0.9;}
    .nav-transparent.nav-top .megamenu > ul > li > a:hover {opacity: 1;}

    .menu-open .nav-transparent.nav-top .megamenu > ul > li > a {color: #1A2C42;}

    .menu-open .main-nav { background: rgba(255, 255, 255, 1); }
}
/* END Transparent menu*/

/************ RESPONSIVE ************/
    @media only screen and (min-width: 1300px) {
        //.nav-spacer-home {min-height: calc(100vh - 84px);}
        .nav-spacer-home {min-height:100vh;}
    }

     @media only screen and (min-height: 1000px) and (max-width: 1400px)  {
        .nav-spacer {min-height: auto;}
    }

    @media only screen and (max-width: 1220px) {
        .main-nav .logo {margin: 0 20px 0 0;}
    }


    .menu-opener {display: none;}

    @media only screen and (min-width: 992px) {
        .show-nav-mobile {display: none!important}
    }

    @media only screen and (max-width: 991px) {

         .main-nav .nav-inner {padding-bottom: 0; align-items:center}

        .show-nav-desktop {display: none!important}
        nav {     height: auto; }

        .main-nav {height: 70px;}
        .nav-moved.main-nav{height: 70px}
        //.main-nav .nav-inner  {height: 10vw;}
        .nav-spacer {padding-top: 10vw;}
        .nav-spacer-2 {padding-top: 15vw;}
        .nav-container {}
        .main-nav .logo, .main-nav .logo img, .main-nav .logo svg {width:160px;height: 22px;}

        .main-nav small {font-size: 13px; text-align: right;display: block;}


        .main-nav.menu-opened {}

        .main-nav .logo {flex: 1;}

        .megamenu {padding-top: 80px; margin-left: 0; position: fixed; top: 0; right: 0; width: 100%; height: 100vh; bottom: 0; pointer-events: none; background-color: #fff; opacity: 0; visibility: hidden; transition: opacity 0.4s, visibility 0s 0.5s; }
        .menu-opened .megamenu { opacity: 1; visibility: visible; transition: opacity 0.4s;pointer-events: auto; overflow-y: auto; -webkit-overflow-scrolling: touch; }


        .megamenu > ul {flex-direction: column;padding: 4vh 8vw 4vh 0;}
        .megamenu > ul > li { padding: 0; height: initial; align-items: flex-end; flex-direction: column;text-align: right; }
        .megamenu > ul > li > a {font-size: clamp(30px, 6vw,46px); font-weight: 900; display: inline-block;padding: 15px 0 15px 40px; color:#040506; position: relative; display: flex; align-items:center}

        .megamenu > ul > li > a:hover {}
        .megamenu > ul > li a:before {display: none;}

        .megamenu > ul > li > a:after { content: ''; display: inline-block; position: relative;bottom:-3px; right: 0; margin-left: 20px; height: 11px; flex:0 0 11px; transform: scaleX(0); width: 11px; background-color: #16F4D0; transition: transform 0.4s 0.4s; transform-origin: right;}
        .megamenu > ul > li.current-menu-item  > a:after {transform: scaleX(1); }


        .megamenu > ul > li:last-child > a {/*background-color: #0e79b2; color: #fff*/}
        .is-loaded .megamenu > ul li a span {max-width: 100%;display: inline-block;}


        .megamenu > ul > li.current-menu-item a:after, 
        .megamenu > ul > li a:hover:after { color: #16F4D0}
        .megamenu > ul > li.current-menu-item > a{}
        

        .menu-image-title:before, .menu-image-title:after {display:none}
        .menu-image-title.menu-image-title-above, .menu-image-title.menu-image-title-below {padding:0;}

        /* Mobile search */
        .nav-search {margin: 30px;}
        .nav-search form {width: 100%}
        .search-flex-mobile {display: flex;}
        .search-flex-mobile input[type="search"]{flex:1; height: 64px;line-height: 64px; font-size: 19px;padding: 20px; background-color: #F7F8F9}
        .search-flex-mobile input[type="submit"] {margin-top: 0; border: 0;padding: 10px 30px;font-size: 14px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.221' height='18.221' viewBox='0 0 18.221 18.221'%3E %3Cg id='icon-search' transform='translate(-0.807 -0.807)'%3E %3Ccircle id='Ellipse_123' data-name='Ellipse 123' cx='6.5' cy='6.5' r='6.5' transform='translate(1.807 1.807)' stroke-width='2' stroke='%23F07100' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' fill='none'/%3E %3Cpath id='Path_3400' data-name='Path 3400' d='M20.684%2C20.684%2C16%2C16' transform='translate(-3.071 -3.071)' fill='none' stroke='%23F07100' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E %3C/g%3E %3C/svg%3E ");background-color: transparent;background-repeat: no-repeat;background-position:center;width: 40px;}

        .search-flex-mobile input[type="search"]::-webkit-input-placeholder {text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}
        .search-flex-mobile input[type="search"]:-ms-input-placeholder {text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}
        .search-flex-mobile input[type="search"]::placeholder { text-transform: none; color: #354E59; font-size: 17px;font-family: 'AvenirLTPro-LightOblique', sans-serif;}


        /* Burger */
        .menu-opener { height: 70px; width: 70px; flex:0 0 70px; right: -22px; position: relative; background-color: transparent; cursor: pointer; overflow: hidden; display: flex; flex-direction: column; align-items: center; justify-content: center; }
        .menu-opened .menu-opener {}

        .nav-transparent.nav-top .menu-opener {background-color: transparent;margin-right: 20px}

        .menu-opener span { position: relative; display: block; width: 26px; height: 2px; background-color: #272D3D; transition: top .2s 0.2s, transform .2s, background-color 0.3s ease; transform-origin: center center; }
        body.nav-dark .menu-opener span { background-color: #fff; }

        .menu-opened .menu-opener span { transition: top .2s, transform .2s 0.2s, background-color 0.3s ease; transform-origin: center center; }
        
        .menu-opener span:first-child { top: -2px; transform: rotate(0deg); }
        .menu-opener span:nth-child(2) { transition: width 0.2s, opacity 0.2s, background-color 0.2s ease; opacity: 0 }
        .menu-opener span:last-child { top: 2px; }

        .menu-opened .menu-opener span:first-child { top: 2px; transform: rotate(45deg); }
        .menu-opened .menu-opener span:nth-child(2) { opacity: 0; width: 0; }
        .menu-opened .menu-opener span:last-child { top: -2px; transform: rotate(-45deg); }

        .body-menu-opened .nav-overlay-mobile { visibility: visible; pointer-events: auto; opacity: 1; transition: opacity 0.5s; }
        .body-menu-opened {overflow:hidden!important;}


        .megamenu .primary-menu, .megamenu .container  {transition: none}
        .remove-nav-inner .megamenu .primary-menu, .remove-nav-inner .megamenu .container  {opacity: 0;transition: opacity 0.4s}
    }

    @media only screen and (max-width: 767px) {
        .main-nav {height: 70px;}
        //.main-nav .nav-inner  {height: 70px;}
        .nav-spacer {padding-top: 70px;}
        .nav-spacer-2 {padding-top: 120px;}
        .nav-moved.main-nav{height: 70px}
        
    }
    @media only screen and (max-width: 380px) {
        .main-nav .logo {margin: 0 0 0 0;}
    }
    @media only screen and (max-width: 340px) {
        
    }



/************ END RESPONSIVE ************/