.tabs {
  display:block;
 
}

/*--
  Tabs Nav
--*/
.tabs-nav  { white-space: nowrap;}
.tabs-nav > ul {
  margin:0;
  padding:0;
  list-style:none;
  display:flex;
   border-bottom: 1px solid #C9C9C9
}




.tabs-nav > ul li {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  float: left;
  padding:16px 0;
  margin: 0 40px 0 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.tabs-nav > ul li:before {
  content: "";
  display: block;
  height: 5px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 0;
  background-color: #4EC4B8;
  bottom: -3px;
  left: 0;
  right: 0;
}

.tabs-nav > ul li.is-active, .tabs-nav > ul li:hover {
  position: relative;
  color: #0E1B3F;
  z-index: 1;
}

.tabs-nav > ul li.is-active:before {
  width: 100%;
}


.tabs-nav > ul li:after {
  content: "|";
  display: inline-block;
  position: relative;
  left: 20px;
  color: #C9C9C9
}

.tabs-nav > ul li:last-child:after {
  display: none;
}

/*--
  Tabs Content
--*/
.tabs-content > div {
  display:none;
  margin: 30px 0;
}
.tabs-content > div.is-active {
  display:block;
}


/* 2nd Level tabs yo */
.tabs .tabs > div > ul {display: block;border-bottom: none;}
.tabs .tabs > div > ul > li {
  display: block;
  border-bottom: 1px solid #C9C9C9;
  margin: 0;
  width: 100%;
  padding:12px 0;
  font-size: 15px
}
.tabs .tabs > div  > ul  > li:after {
  display: none;
}

.tabs .tabs .tabs-nav {flex:0 0 120px;margin-right: 30px;}
.tabs .tabs .tabs-content {flex:1;}

.tabs .tabs .tabs-content > div {
  margin: 0;
}

.tabs .tabs .list-1 li span { border-color: #C9C9C9; padding: 14px 0;    font-size: 15px;}


@media only screen and (max-width:500px) {
  .tabs-nav > ul li {margin: 0 30px 0 0; font-size: 14px;}
  .tabs .tabs>div>ul>li {font-size: 14px;}
  .tabs-nav > ul li:after {display: none;}
  .tabs .tabs .tabs-nav {flex: 0 0 90px;margin-right: 10px;}
  .tabs .tabs .tabs-content .list-1 span{line-height: 1.2; font-size: 13px;}
}
