
body {
  font-size: 18px;
  line-height: 1.7;
}


.font-primary {font-family: $font-primary!important}


p, .p {@include margin-bottom(2rem);}

small, .small {font-size: 18px;}
 .xsmall {font-size: 16px;}

/* Headings */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6  {
	@include margin-bottom(2rem);
  font-weight: 800;
  color:#040506
}

h1, .h1 {
  @include font-size(3.5rem);
}
h2, .h2 {
  @include font-size(2rem);
}
h3, .h3 {
  @include font-size(1.8rem);
}
h4, .h4 {
  @include font-size(1.6rem);
}
h5, .h5 {
  @include font-size(1.5rem);
}
h6, .h6 {
  @include font-size(1.2rem);
}


h6.small, .h6.small {
  font-size: 21px;
}



.heading-1 {font-size: 20px; color: #0E1B3F; font-weight: 400}
.heading-1:after {content: '';margin-top: 10px;height: 5px;width: 28px;background-color: #4EC4B8;display: block;}

.heading-after:after {content: '';margin-top: 10px;height: 5px;width: 28px;background-color: #4EC4B8;display: block;}

.lead {
  line-height: 1.4;
	font-size: clamp(22px, 1.7vw, 32px);
  color:#4F5050

}

.lead-2 {
  line-height: 1.5;
  font-size: clamp(20px, 1.7vw, 24px);
}

blockquote {margin-bottom: 40px; padding-left: 120px;padding-top: 30px;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='86.52' height='71.226' viewBox='0 0 86.52 71.226'%3E %3Cg id='icon-quote' transform='translate(-200 -4061)'%3E %3Cpath id='Path_506' data-name='Path 506' d='M21.124%2C0%2C0%2C33.733V71.226H37.492V33.733H18.746L38.607%2C0Z' transform='translate(200 4061)' fill='%23b3f2dd'/%3E %3Cpath id='Path_507' data-name='Path 507' d='M64.037%2C0%2C42.912%2C33.733V71.226H80.4V33.733H61.658L81.52%2C0Z' transform='translate(205 4061)' fill='%23b3f2dd'/%3E %3C/g%3E %3C/svg%3E ");
background-repeat: no-repeat;
}

blockquote p {font-size: 30px; line-height: 1.4; font-weight: 300; }




.heading-height {font-size: clamp(40px, 6.5vh, 11vh);line-height: 1.1}
.lead-height { font-size: clamp(18px, 3vh, 28px);}


.ssm {font-size: 15px!important}


@media (max-width: 1300px) {
  //.heading-height {font-size: clamp(36px, 5vh, 8vh);}
  .heading-height {font-size: calc(2rem + 1.2vh);}
  
  body {
    font-size: 17px;
  }
}

@media (max-width: 959px) {
  //.heading-height {font-size: clamp(36px, 5vh, 8vh);}
  .heading-height {font-size: calc(1.6rem + 1vh);}
  .lead {font-size: clamp(20px, 2vw, 26px);}
  .lead-2 {font-size: clamp(20px, 2vw, 24px);}
}


@media (max-width: 1100px) {
  //.h1-height {font-size: clamp(30px, 6vw, 5vw);}
}






@media only screen
and (min-device-width:768px)
and (max-device-width:1024px)
and (orientation:portrait)
and (-webkit-min-device-pixel-ratio:2) {
  .heading-height {font-size: calc(1.8rem + 1vh);}
  .lead {font-size: clamp(18px, 1.7vw, 22px);}
}

@media only screen
and (min-device-width:834px)
and (max-device-width:834px)
and (orientation:portrait)
and (-webkit-min-device-pixel-ratio:2) {
  .heading-height {font-size: calc(1.8rem + 1vh);}
  .lead {font-size: clamp(18px, 1.7vw, 22px);}
}

@media only screen
and (min-device-width:1024px)
and (max-device-width:1024px)
and (orientation:portrait)
and (-webkit-min-device-pixel-ratio:2) {
  .heading-height {font-size: calc(1.8rem + 1vh);}
  .lead {font-size: clamp(18px, 1.7vw, 22px);} 
}
