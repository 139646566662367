body {
  font-weight: 400;
  overflow-x: hidden;
  font-family: $font-primary;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    transition:background-color 0.4s;
      overflow-y:auto!important; 
	-webkit-text-rendering: optimizeSpeed;
	text-rendering: optimizeSpeed;
}

img {max-width: 100%;}
a {}
hr { margin: 1rem 0; background-color: #040506; opacity: 1; border: none;height: 2px!important; }
hr.hr-thin {height: 1px!important;}

hr.hr-short {width: 30%; background-color: #16F4D0;margin-top: -3px;}

.h-100-vh {height: 100vh;}


.fw-semi {font-weight: 700}

.link-underline {text-decoration: underline;}
.link-underline:hover {text-decoration: none;}

.button { border: none; margin: 0; padding: 0; width: auto; overflow: visible; background: transparent;    /* inherit font & color from ancestor */ color: inherit; font: inherit;    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */ line-height: normal;    /* Corrects font smoothing for webkit */ -webkit-font-smoothing: inherit; -moz-osx-font-smoothing: inherit;    /* Corrects inability to style clickable `input` types in iOS */ -webkit-appearance: none; text-align: inherit; outline: none; cursor: pointer; }
.button::-moz-focus-inner { border: 0; padding: 0; }
.button:focus {outline: none;}
.button:disabled { opacity: 0.5;cursor: default;}
.button svg circle {transition:fill 0.4s}
.button:hover svg circle {fill:$color-blue}
.button.btn-left {margin-right: 4px;}
.button.btn-right {margin-left: 4px;}


.flex-shrink-210 {flex:0 0 36%}
.list-unstyled {padding: 0;margin: 0;list-style: none;}
.link-inherit {color: inherit;text-decoration: none;}

.pointer-event-none {pointer-events:none}

.z-1 {z-index: 1}
.z-2 {z-index: 2}
.z-3 {z-index: 3}
.z-4 {z-index: 4}
.z-5 {z-index: 5}

.mw-80 {max-width: 80px;}
.mw-420 {max-width: 420px;}
.mw-500 {max-width: 500px;}
.mw-600 {max-width: 600px;}
.mw-700 {max-width: 700px;}
.mw-800 {max-width: 800px;}

@include media-breakpoint-up(sm) {
  .mw-75p {max-width: 75%;}
}

.line-bottom {border-bottom: 1px solid #B5B6BB;}
.line-top {border-top: 1px solid #B5B6BB;}

.margin-bottom-min-70 {margin-bottom: -70px;}

.lh-small {line-height: 1.4; }

.img-absolute { position: absolute; left: 0; right: 0; bottom: 0; top: 0; height: 100%; object-fit: cover; background-size: cover; background-position: center; background-repeat: no-repeat; }
.bg-cover-1 { position: absolute; left: 0; right: 0; bottom: 0; background-size: contain; background-position: bottom; background-repeat: no-repeat; }


.img-ratio {width: 100%;position: relative; overflow: hidden;z-index: 2}
.img-ratio img,  .img-ratio video {position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;-o-object-fit: cover; object-position: 50% 50%;height: 100%;}

.img-ratio-w {width: 100%;position: relative; overflow: hidden;z-index: 2}
.img-ratio-w img,  .img-ratio-w video {position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: contain;-o-object-fit: contain; object-position: 50% 100%;width: 100%;}


.image-height-1 {padding-bottom: 100%;}
.image-height-2 {padding-bottom: 110%;}
.image-height-3 {padding-bottom: 71.43%;}
.image-height-4 {padding-bottom: 107%;}
.image-height-5 {padding-bottom: 50%;}
.image-height-6 {padding-bottom: 40%;}
.image-height-7 {padding-bottom: 90%;}



@include media-breakpoint-up(sm) {
  .image-shape {border-radius: 0 0 100% 0;}
}


.object-position-left {object-position: left!important;}


.list-inline-1 {list-style: none;padding: 0;margin: 0 0 30px!important;line-height: 1.1;}
.list-inline-1 li {display: inline-block; font-size: 12px;margin-right: 6px;}
.list-inline-1 li:last-child {margin-right: 0;}
.list-inline-1 li:after {content: ''; height: 14px; display: inline-block;width: 1px;background-color: $body-color;margin-left: 6px; position: relative;bottom: -2px;}
.list-inline-1 li:last-child:after {display: none;}

.list-inline-1 li a{}

.link-small {color: $body-color; font-size: 14px; transition:color 0.4s}
.link-small:hover {color: #000; }

.icon-link-1 {padding: 5px 0 5px 5px;}
.icon-link-1 svg path{transition:fill 0.4s}
.icon-link-1:hover svg path{fill:#000}

.icon-link-2 {padding: 5px 5px 5px 0;}
.icon-link-2 svg path{transition:fill 0.4s}
.icon-link-2:hover svg path{fill:#fff}
/* Home */
.header-home{min-height: 100%;}
.hero-image { height: 100vh; width: 100%; display: flex;min-height: 380px;max-height: 1100px}
@include media-breakpoint-down(lg) { 
  .hero-image { height: 50vh;min-height: 300px }
}
@include media-breakpoint-down(md) { 
  .hero-image { height: 30vh;}
}

 @media only screen
and (min-device-width:768px)
and (max-device-width:1024px)
and (orientation:portrait)
and (-webkit-min-device-pixel-ratio:2) {
  .hero-image {max-height: 800px}
}

@media only screen
and (min-device-width:834px)
and (max-device-width:834px)
and (orientation:portrait)
and (-webkit-min-device-pixel-ratio:2) {
  .hero-image {max-height: 800px}
}

@media only screen
and (min-device-width:1024px)
and (max-device-width:1024px)
and (orientation:portrait)
and (-webkit-min-device-pixel-ratio:2) {
  .hero-image {max-height: 800px}
}



.right-element {width: 5vw; margin-right: -5vw;}
.right-element > div {display: block;width: 100%;padding-bottom: 100%;background: #FDB515;border-radius: 0 0 100% 0;-moz-border-radius: 0 0 100% 0;-webkit-border-radius: 0 0 100% 0;}

@-webkit-keyframes bounce {
    0%, 100% { -webkit-transform: translateY(0); }
    50% { -webkit-transform: translateY(-6px); }
}
@-moz-keyframes bounce {
    0%, 100% { -moz-transform: translateY(0); }
    50% { -moz-transform: translateY(-6px); }
}
@-o-keyframes bounce {
    0%, 100% { -o-transform: translateY(0); }
    50% { -o-transform: translateY(-6px); }
}
@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-6px); }
}



.home-right-element {position: absolute;right: 0;bottom: 0;z-index: 2;width: 60vh; max-width: 400px; text-align: right; display: flex; align-items:flex-end;}
.home-right-element svg{width: 100%;}
@include media-breakpoint-down(xl) { 
  .home-right-element {width: 30vh; max-width: 280px;}
}
@include media-breakpoint-down(md) { 
  .home-right-element { max-width: 180px;}
}



.services-right-element {position: absolute;right: 0;bottom: 0;top: 0;z-index: 2;max-width: 400px; text-align: right; display: flex; align-items:center;}
.services-right-element svg{width: 100%;width: 60vh; }

@include media-breakpoint-down(xl) { 
  .services-right-element {width: 30vh; max-width: 280px;}
}
@include media-breakpoint-down(md) { 
  .services-right-element { max-width: 180px;}
}

.team-linkedin {}
.team-linkedin svg path {transition:fill 0.4s}
.team-linkedin:hover svg path {fill:#040506}

.t-flex.order-lg-2 {display: flex; flex-direction:column; justify-content:flex-end}
.t-flex .h6 {white-space: nowrap;}
.t-flex p {font-size: 17px!important; line-height: 1.3;}


@include media-breakpoint-down(xl) { 
  .t-flex .h6 {font-size: 14px!important}
  .t-flex p {font-size: 14px!important}
  .team-linkedin svg{width: 80px;height: 80px;}
}

@include media-breakpoint-up(sm) {
  .t-flex {height: 60px;}
}

footer {padding: 30px 0; font-size: 13px; color: #818282}
footer a {color: #818282; text-decoration: none; transition:color 0.4s}
footer a:hover {color:rgba(83,233,193,0.8)}


.row-logos { margin-left: -20px;margin-right: -20px;}
.row-logos > div {padding:0 20px;}


@include media-breakpoint-down(md) {
.row-logos { margin-left: -10px;margin-right: -10px; justify-content:center}
.row-logos > div {padding:0 10px;}
.row-logos > div img{max-width: 90%}
}