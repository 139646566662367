

.barba-container, #viewport {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 9;
  max-width: 2000px
}


main {
  transition:background-color 0.8s;
  width: 100%;
}

.content {
  transition: all 0.3s ease-out;
  opacity: 1;
  transform: translateY(0);
}

.transition {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #c8cc98;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
  z-index: 1;

  > h2 {
    font-size: 40px;
    color: #202020;
  }
}





/*Downloaded from https://www.codeseek.co/erwanx/background-noise-with-canvas-GQWPjB */
.main-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 100vh;
    background: #0e0e0e;
    color: #fff;
    text-align: center;
}




.reveal {
  visibility: hidden;
  position: relative;
  overflow:hidden;
}

.reveal img {
  height: 100%;
  width: 100%;
  object-fit: cover; 
  transform-origin: left;
}



