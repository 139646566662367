// No scroll on no-touch devices when loader is present - Laptops and pcs ect..
.loader-cover {position: fixed;bottom: 0;top: 0;left: 0;right: 0;z-index: 9999;       visibility: visible; transition:visibility 0s 1s;}
.is-loaded .loader-cover {visibility: hidden;}

// No scroll on touch devices when loader is present
.is-loading, .is-loading body{overflow: hidden;}
.is-loading .scroll-content, .is-loading main{overflow: hidden;}
.is-loading #viewport{pointer-events:none!important}

#logo-blocks rect {opacity: 0}
#logo-text  {opacity: 0}

// Home Right column comes in
.loader-in-right { opacity: 0;transition:opacity 0s 1.2s;}
.is-loaded .loader-in-right { opacity: 1}

@media only screen and (max-width:991px) {
	.loader-in-right { opacity: 0;transition:opacity 0.4s 1.2s;}
	.is-loaded .loader-in-right { opacity: 1}
}


// Home right elem
.loader-in-element { opacity: 0;transition:opacity 2s 1.2s;}
.is-loaded .loader-in-element { opacity: 1}




// Bottom right pizza slice comes in
.loader-in-bottom { opacity: 0;transition:opacity 0.4s 0s;}
.is-loaded .loader-in-bottom { opacity: 1}

// Home left column comes in
.loader-in-left { transform:translateY(-60px); opacity: 0;transition:opacity 0.6s 0.6s;}
.is-loaded .loader-in-left {transform:translateY(0); opacity: 1}

// Home left column comes in
.loader-in { transform:translateY(-60px); opacity: 0;transition:aopacityll 0.6s 0s;}
.is-loaded .loader-in {transform:translateY(0); opacity: 1}

// Nav comes in
.main-nav{ transform:translateY(-100%);}
.is-loaded .main-nav {transform:translateY(0); }
.main-nav > nav {opacity: 0}

// Progress cursor when page is transitioning
.is-transitioning {  pointer-events: none;  cursor: progress;}

// Gsap Dev tools z-index issue
//.gs-dev-tools {z-index: 999}


// Preloader elements
.grid-loader {  position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 99; opacity: 0; pointer-events:none; visibility: hidden;}
.grid-loader ul {list-style: none;padding: 0;margin: 0; height:100vh;}
.grid-loader ul li {position: absolute;transition:all 0.8s;overflow: hidden;}
.grid-loader ul li img {position: absolute;left: 0;top: 0; object-fit:cover; height: 100%;}

			.grid-loader ul li:first-child {top: 0;left: 0;right: 0;bottom: 0; z-index: 3; background-color: rgba(0,0,0,0.7); display: flex; align-items: center;justify-content: center;}






			.grid-loader ul li:nth-child(2)   {width: 100%; top: 0;  right: 0; background-size: cover; background-position: center;}
.is-loaded 	.grid-loader { transition:all 0.6s 1.2s;}

.is-loaded 	.grid-loader ul li img {position: absolute;transition:all 0.6s;transform:translateY(-100%); transform-origin:top;}
           
           	.grid-loader ul li:nth-child(2)   {transition:width 0.6s 0s,height 0.6s 0s, opacity 0s 1.2s,min-height 0.6s 0.6s;min-height: 100%;height: 100%;  }
.is-loaded 	.grid-loader ul li:nth-child(2)   {width: 50vw; opacity: 0;height:100%;transform:translateX(0)}


			.grid-loader ul li:nth-child(2) img {position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;-o-object-fit: cover;object-position: 50% 50%;height: 110%;top: -10%;}
.is-loaded 	.grid-loader ul li:nth-child(2) img {transform:translateX(0);opacity: 1}


@media only screen and (max-width:991px) {
	//.grid-loader {display: none;}
	.is-loaded 	.grid-loader ul li:nth-child(2)   {width: 0; opacity: 0;height:100%}
	.logo-intro-anim svg {max-width: 80%;width: 240px;}
}