/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background:transparent;
  border: none;
  color: #273d46;
  transition:all 0.4s
}

.flickity-button:hover {
  cursor: pointer;
  color:#273d46;
  opacity: 1!important;
  transform: scale(1.1);
}

.flickity-button:focus {
  outline: none;
  box-shadow: none;
}

.flickity-button:active {
  //opacity: 0.6;
}

.flickity-button:disabled {
  //opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 12vw;
  width: 60px;
  margin: 0;
  padding: 0;
}


// .no-touch .flickity-prev-next-button {
//   opacity: 0;
// }

// .no-touch .flickity-enabled:hover .flickity-prev-next-button{
//   opacity: 0.6;
// }

.flickity-prev-next-button.previous { left: 0; /*background-image: linear-gradient(to right, rgb(255, 255, 255) , transparent);*/}
.flickity-prev-next-button.next { right: 0; /*background-image: linear-gradient(to right, transparent , rgb(255, 255, 255));*/}

.flickity-prev-next-button .flickity-button-icon {
      width: 35px;
    border-radius: 2px;
    position: relative;
    transition: all 0.4s;
    background: white;
    padding: 10px;
}

.no-touch .flickity-prev-next-button.previous .flickity-button-icon {left: 6px;padding: 10px 8px 10px 12px;}
.no-touch .flickity-prev-next-button.next .flickity-button-icon {right: 6px;padding: 10px 12px 10px 8px;}

.no-touch .flickity-enabled:hover .flickity-prev-next-button.previous .flickity-button-icon {left: 0;}
.no-touch .flickity-enabled:hover .flickity-prev-next-button.next .flickity-button-icon {right: 0;}


/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}