/* Nav */
.carousel-overflow {}
.carousel-nav .carousel-cell {padding:50px;color: #040506; font-size: 20px; line-height: 1.2; font-weight: 700; cursor:pointer; position: relative;min-height: 100%}
.c-next {padding:50px;}
.carousel-nav .carousel-cell:hover {text-decoration: underline;}

  .carousel-nav .carousel-cell:first-child {padding-left: 0; }

.carousel-1-3.carousel-nav .carousel-cell { width: 30%;}
.carousel-1-3.carousel-nav .carousel-cell:nth-child(2) { width: 40%;}

.carousel-1-4.carousel-nav .carousel-cell { width: 20%;}
.carousel-1-4.carousel-nav .carousel-cell:nth-child(2) { width: 30%;}
.carousel-1-4.carousel-nav .carousel-cell:nth-child(3) { width: 30%;}


.carousel-nav .carousel-cell:after { content: ''; display: block; width: 0; height: 0; position: absolute; bottom: 0; left: 50px; border-left: 14px solid transparent; border-right: 14px solid transparent; border-bottom: 0px solid #1C1D1E; transition: border 0.3s }
.carousel-nav .carousel-cell:first-child:after { left: 0; }
.carousel-nav .carousel-cell.is-nav-selected { text-decoration: underline; }
.carousel-nav .carousel-cell.is-nav-selected:after { border-left: 14px solid transparent; border-right: 14px solid transparent; border-bottom: 13px solid #1C1D1E; }


.carousel-1-4.carousel-nav .carousel-cell:after {border-bottom: 0px solid #fff;}
.carousel-1-4.carousel-nav .carousel-cell.is-nav-selected:after { border-bottom: 13px solid #fff; }

.row-numbers {display: flex}
.row-numbers div {color: #fff; font-size: 16px;padding:70px 50px 25px 50px;width: 20%;}
.row-numbers div:first-child {padding-left: 0; }

.row-numbers div:nth-child(2) { width: 30%;}
.row-numbers div:nth-child(3) { width: 30%;}

@media only screen and (min-width:841px) {
  
}



/* Carousel overflow*/
.carousel-overflow {position: relative;}
.carousel-overflow:before { content: ""; background: #53E9C1; right: 50%; left: -9999px; position: absolute; top: 0; bottom: 0; z-index: -1; }
.carousel-overflow:after { content: ""; background: #87EEFF; left: 50%; right: -9999px; position: absolute; top: 0; bottom: 0; z-index: -1; }



/* Main */
.carousel-holder {padding: 100px 0;}
.carousel-main .carousel-cell {width: 100%;}
.carousel-main .carousel-cell small {font-size: 13px; letter-spacing: 3px}
.carousel-main .carousel-cell h3 { @include font-size(2.1rem); }
.carousel-main .carousel-cell p {font-size: 20px}
.carousel-main .flickity-page-dots {display: none;}
.bg-black .carousel-main .carousel-cell p {color:rgba(255,255,255,0.8);}

.c-next {cursor: pointer;}
.c-next path{transition:stroke 0.4s, opacity 0.4s}
.c-next:hover #n-arrow path{stroke:#53E9C1;}
.c-next:hover #n-text path{opacity: 1}

.c-next-dark #n-text path {fill:#363738}
.c-next-dark.c-next #n-arrow path{stroke:#53E9C1;}
.c-next-dark.c-next:hover #n-arrow path{stroke:#040506;}

.carousel-main-next  {}
.carousel-icon {height: 80px;}

.c-top-next {display: none;}

@include media-breakpoint-down(xxl) {
  .carousel-holder {padding: 80px 0;}
  .carousel-nav .carousel-cell {padding:40px 30px; font-size: 18px; }
  .carousel-nav .carousel-cell:after { left: 30px; }
  .c-next {padding:30px;}

  .row-numbers div {font-size: 15px;padding:40px 30px 20px 30px;}


}


@include media-breakpoint-down(xl) {
  .carousel-holder {padding: 60px 0;}
}

@media only screen and (max-width:840px) {
  .row-numbers {display: none;}
  .carousel-nav {z-index: 3; overflow: visible;}
  .carousel-nav .flickity-viewport{z-index: 3; overflow: visible;}
  .carousel-1-3.carousel-nav .carousel-cell { width: 70%!important;}
  .carousel-1-4.carousel-nav .carousel-cell { width: 70%!important;}

  .carousel-nav .carousel-cell span{opacity: 0.4; filter:blur(2px); transition:opacity 0.4s, filter 0.4s}
  .carousel-nav .carousel-cell.is-selected span{opacity: 1;filter:blur(0)}

  .c-top-next {display: block; left: calc(70% - 4vw); right: -9999px; position: absolute; top: 0; bottom: 0; z-index: 3; display: flex;align-items:center;padding-left: 15%; }

  .carousel-main .carousel-cell p {font-size: 18px}

}